'use client'

import { useEffect } from 'react'

const imageSources = [
  '/cars/user.webp',
  '/cars/opponent.webp',
  '/cars/arrows/user.svg',
  '/cars/arrows/opponent.svg',
  '/cars/light/user.svg',
  '/cars/light/opponent.svg',
  '/illustrations/popover.svg',
  '/illustrations/finish-line.svg',
  '/illustrations/flag1.webp',
  '/illustrations/flag2.webp',
  '/illustrations/coin-shadow.webp',
]

export const PreloadImages = () => {
  useEffect(() => {
    imageSources.forEach((src) => {
      const img = new Image()
      img.src = src
    })
  }, [])

  return null
}
